.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range{
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #818181;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #000;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #000;
  font-size: 12px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  margin-bottom: -2px;  
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  border: none;
  background-color: #000;
  border-radius: 15px;
  background-repeat: no-repeat;
  border: 3px double #fff;
  background-position: center;
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}


/* For Firefox browsers */
.thumb::-moz-range-thumb {
  border: none;
  background-color: #000;
  border-radius: 15px;
  background-repeat: no-repeat;
  border: 3px double #000;
  background-position: center;
  cursor: pointer;
  height: 10px;
  width: 10px;
  pointer-events: all;
  position: relative;
}
.custom-control-label {
  color: #000;
  margin-top: 5px;
}
