@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: "Heebo","Nazanin";
  color: #000000;
  background-color: #ffffff;
}

.magniimg {
  max-width: none !important;
  max-height: none !important;
}

.layout_padding {
  padding: 75px 0;
}

.layout_padding2 {
  padding: 45px 0;
}

.layout_padding2-top {
  padding-top: 45px;
}

.layout_padding2-bottom {
  padding-bottom: 45px;
}

.layout_padding-top {
  padding-top: 75px;
}

.layout_padding-bottom {
  padding-bottom: 75px;
}

.custom_heading {
  text-transform: uppercase;
  font-weight: bold;
}

/*header section*/
.hero_area {
  height: 98vh;
  background-color: #fcd9b8;
}

.sub_page .hero_area {
  height: auto;
}

.hero_area.sub_pages {
  height: auto;
}

.header_section .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.header_section .nav_container {
  margin: 0 auto;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-link {
  padding: 10px 30px;
  color: #ffffff;
  text-align: center;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.navbar-brand,
.navbar-brand:hover {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  color: #fafcfd;
}

.custom_nav-container .nav_search-btn {
  background-image: url(../public/images/search-icon.png);
  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: 7px;
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
}

.navbar-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navbar-brand img {
  width: 45px;
  margin-right: 5px;
}

.navbar-brand span {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  margin-top: 5px;
}

.custom_nav-container {
  z-index: 10;
  padding: 15px 0;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler .navbar-toggler-icon {
  background-image: url(../public/images/menu.png);
  background-size: 55px;
}

/*end header section*/
/* slider section */
.slider_section {
  padding: 8% 0;
}

.slider_item-box {
  width: 100%;
  height: 100%;
}

.slider_item-box .container {
  height: 100%;
}

.slider_img-box {
  width: 85%;
  margin: 0 auto;
}

#protal-magnify div {
  transform: none !important;
  inset: 8vh 204px 0 53vw !important; 
}

.slider_img-box img {
  width: 100%;
}

.slider_item-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.yellow-circle-small::after{
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FFA800;
  position: absolute;
  right: -12px;
  top: 37%;
  z-index: 5;
}

.slider-btn1 {
  display: inline-block;
  padding: 10px 35px;
  background-color: #7ABAB4;
  border: 1px solid #7ABAB4;
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
}

.slider-btn1:hover {
  background-color: transparent;
  color: #7ABAB4;
}

.slider-btn2 {
  display: inline-block;
  padding: 10px 35px;
  background-color: #252525;
  font-weight: 800;
  font-size: 18px;
  border: 1px solid #252525;
  color: #ffffff;
}

.slider-btn2:hover {
  background-color: transparent;
  color: #252525;
}

.slider_item-detail h1 {
  font-size: 68px;
  width: 100%;
  text-transform: uppercase;
}

.slider_item-detail p {
  margin: 35px 0;
  font-size: 18px;
}

.slider_section #carouselExampleControls,
.carousel-inner,
.carousel-item {
  height: 100%;
}

.slider_section .carousel-control-prev,
.slider_section .carousel-control-next {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
  background-color: #e09145;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.slider_section .carousel-control-prev:hover,
.slider_section .carousel-control-next:hover {
  background-color: #ffffff;
}

.slider_section .carousel-control-prev {
  left: 2%;
  background-image: url(../public/images/prev.png);
}

.slider_section .carousel-control-next {
  right: 2%;
  background-image: url(../public/images/next.png);
}

/* end slider section */
.bg {
  background-image: url(../public/images/bg.jpg);
  background-size: cover;
}

.about_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about_detail-box p {
  margin-top: 30px;
  margin-bottom: 45px;
}

.about_detail-box a {
  display: inline-block;
  padding: 7px 25px;
  background-color: #071777;
  border: 1px solid #071777;
  color: #ffffff;
}

.about_detail-box a:hover {
  background-color: transparent;
  color: #071777;
}

.about_img-box img {
  width: 100%;
}

.brand_section {
  text-align: center;
  background-color: #e09145;
}

.brand_section h3 {
  color: #fcfcfc;
}

.brand_section .brand_item-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.brand_item-box {
  width: 100%;
}

.brand_detail-box {
  margin-top: 20px;
}

.brand_detail-box h5 {
  text-transform: uppercase;
  font-weight: bold;
  color: #242425;
}

.brand_detail-box h5 span {
  color: #17181d;
}

.brand_detail-box h6 {
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
}

.why_section .row {
  padding: 35px 0;
}

.why_section .row .col-md-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 25px 0;
}

.why_section .img_box {
  min-width: 125px;
  min-height: 125px;
  margin-left: 25px;
  border: 1.5px solid #e3e2e2;
  border-radius: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.why_section .img_box img {
  width: 50px;
}

.why_section .detail_box h5 {
  font-weight: bold;
  color: #e09145;
}

.client_section .row {
  padding: 25px 0;
}

.client_section .row .col-12 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.client_section .detail_box {
  min-width: 150px;
  margin-left: 25px;
  text-align: center;
}

.client_section .detail_box img {
  width: 100%;
}

.client_section .detail_box h5 {
  font-weight: bold;
}

.client_section .detail_box h6 {
  color: #0b3275;
}

.client_section .detail_box .name_box {
  margin-top: 15px;
}

.client_section .detail_text {
  padding-top: 10px;
}

.client_section #carouselExampleControls,
.client_section .carousel-inner,
.client_section .carousel-item {
  height: 100%;
}

.client_section .carousel-control-prev,
.client_section .carousel-control-next {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 45px;
  border: none;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
  background-color: #e09145;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.client_section .carousel-control-prev:hover,
.client_section .carousel-control-next:hover {
  background-color: #e09145;
}

.client_section .carousel-control-prev {
  left: 2%;
  background-image: url(../public/images/prev.png);
}

.client_section .carousel-control-next {
  right: 2%;
  background-image: url(../public/images/next.png);
}

.contact_section {
  color: #fff;
  background: linear-gradient(rgb(11, 63, 131), rgba(9, 65, 110, 0.199)), url("../public/projects/1 (6).jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.contact_section input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ffffff;
  height: 40px;
  padding-top: 45px;
  padding-bottom: 20px;
  background-color: transparent;
  outline: none;
  color: #fff;
}

.contact_section input::-webkit-input-placeholder {
  color: #fff;
}

.contact_section input:-ms-input-placeholder {
  color: #fff;
}

.contact_section input::-ms-input-placeholder {
  color: #fff;
}

.contact_section input::placeholder {
  color: #fff;
}

.contact_section input.message-box {
  padding-top: 135px;
}

.contact_section button {
  background-color: #071777;
  padding: 12px 55px;
  outline: none;
  border: none;
  border: 1px solid #071777;
  color: #fff;
}

.contact_section button:hover {
  color: #071777;
  background-color: transparent;
}

.info_section {
  background-color: #000;
}

.info_items {
  width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.info_items .item {
  width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.info_items .item .img-box {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
}

.info_items .item .detail-box {
  margin-top: 25px;
  color: #fff;
}

.info_items {
  position: relative;
}

.info_items a {
  position: relative;
}

.info_items::before {
  content: "";
  position: absolute;
  top: 25%;
  width: 80%;
  height: 1px;
  background-color: #fff;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.info_items .item .img-box.box-1 {
  background-image: url(../public/images/location-blue.png);
}

.info_items .item .img-box.box-2 {
  background-image: url(../public/images/telephone-blue.png);
}

.info_items .item .img-box.box-3 {
  background-image: url(../public/images/envelope-blue.png);
}

.info_items .item .img-box:hover {
  background-color: #17181d;
}

.info_items .item .img-box:hover.box-1 {
  background-image: url(../public/images/location-white.png);
}

.info_items .item .img-box:hover.box-2 {
  background-image: url(../public/images/telephone-white.png);
}

.info_items .item .img-box:hover.box-3 {
  background-image: url(../public/images/envelope-white.png);
}

/* footer section*/
.footer_section {
  background-color: #273135;
  padding: 20px;
  font-family: "Dosis", sans-serif;
  font-weight: 500;
}

.footer_section p {
  color: #fbfcfd;
  margin: 0;
  text-align: center;
}

.footer_section a {
  color: #fbfcfd;
}
.button-more:hover {
  background-color: white;
  border: 1px solid #071777 !important;
  color: #071777 !important;
}

@font-face {
  font-family: "Nazanin";
  src: url(../public/PeydaFaNum-Regular.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600&family=Roboto:wght@700;800&display=swap");